<script setup>

import { useStore } from "vuex";
import CategoriesRow from './components/CategoriesRow.vue';
import { onBeforeMount } from 'vue';
import bootstrap from "bootstrap/dist/js/bootstrap";
import axios from "axios";
import validSession from "../assets/js/valid-session";
import loadGif from "@/assets/img/loading.gif"
import { toast } from 'vue3-toastify';

let icons = [];
let selectCategories = {};
let selectCategoriesMove = [];

const getIcons = async () => {

    axios.get('icons')
    .then(res => {
        
        if(res.status == 200){

            icons = res.data;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getExpenseCategories = async () => {

    axios.get('categories?type=despesa')
    .then(res => {
        
        if(res.status == 200){

            store.state.categoriesExpense = res.data;
            store.state.loadingCategoriesExpense = false;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getRevenueCategories = async () => {

axios.get('categories?type=receita')
.then(res => {
    
    if(res.status == 200){

        store.state.categoriesRevenue = res.data;
        store.state.loadingCategoriesRevenue = false;

    }
    
})
.catch(err => {
    console.error(err); 
})

}

const colors = {
    1: '#000000',
    2: '#6A5ACD',
    3: '#00CED1',
    4: '#006400',
    5: '#DAA520',
    6: '#696969',
    7: '#4682B4',
    8: '#8B0000',
    9: '#556B2F',
    10: '#D2691E',
    11: '#B8860B',
    12: '#9ACD32',
    13: '#FF69B4',
    14: '#C71585',
    15: '#4169E1',
    16: '#6E39B0',
    17: '#A3E2EC',
    18: '#3727C8',
    19: '#3DD025',
    20: '#E3B41C',
    21: '#A7368E',
    22: '#D1AE6A',
    23: '#78CB99',
    24: '#928577',
    25: '#EB6618',
}

const store = useStore();

onBeforeMount(() => {

    validSession.isLogged();

    store.state.categoriesExpense = {};
    store.state.categoriesRevenue = {};

    store.state.category_icon = 'fa-receipt';
    store.state.category_color = '#000'
    store.state.category_name = '';
    store.state.category_type = '';
    store.state.category_sub = false;
    store.state.category_parent = 0;
    store.state.category_id = '';

    store.state.validation = {
        nameHasError: false,
        nameMessageError: '',
        parentHasError: false,
        parentMessageError: '',
    };

    store.state.loadingCategoriesExpense = true;
    store.state.loadingCategoriesRevenue = true;

    store.state.deleted_category = {};
    store.state.destiny_category = 0;

    getIcons();
    getExpenseCategories();
    getRevenueCategories();
});

const createCategory = (type) => {

    if(type == 'despesa'){
        selectCategories = store.state.categoriesExpense;
    }
    else{
        selectCategories = store.state.categoriesRevenue;
    }

    store.state.category_type = type;

}

const editCategory = (data) => {

    createCategory(data.type);

    store.state.category_icon = data.icon;
    store.state.category_color = data.color
    store.state.category_name = data.name;
    store.state.category_type = data.type;
    store.state.category_sub = data.sub == 'Yes' ? true : false;
    store.state.category_parent = data.parent_id;
    store.state.category_id = data.id;

}

const clearFields = () => {
    store.state.category_icon = 'fa-receipt';
    store.state.category_color = '#000'
    store.state.category_name = '';
    store.state.category_type = '';
    store.state.category_sub = false;
    store.state.category_parent = '';
    store.state.category_id = '';
}

const openModal = (id) => {

    let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById(id));

    modal.show();

}

const closeModal = (id) => {

    let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById(id));

    modal.hide();

}

const submit_category = async () => {

    let data = new FormData();

    store.state.validation.nameHasError = false;
    store.state.validation.parentHasError = false;

    data.append('name', store.state.category_name);
    data.append('icon', store.state.category_icon);
    data.append('color', store.state.category_color);
    data.append('type', store.state.category_type);
    data.append('sub', store.state.category_sub == true ? 'Yes' : '');
    data.append('parent_id', store.state.category_sub == true ? store.state.category_parent : 0);

    let url = '/categories';
    let toast_message = 'Categoria criada com sucesso!';

    if(store.state.category_id != ''){
        url = '/categories/update/'+store.state.category_id;
        toast_message = 'Categoria atualizada com sucesso!';
    }

    await axios.post(url, data)
    .then(res => {

        if(res.data.error){

            let messages = res.data.messages;

            Object.entries(messages).forEach((value) => {

                if(value[0] == 'name'){
                    store.state.validation.nameHasError = true;
                    store.state.validation.nameMessageError = value[1];
                }

                else if(value[0] == 'parent_id'){
                    store.state.validation.parentHasError = true;
                    store.state.validation.parentMessageError = value[1];
                }


            })

        }
        else{

            let type = store.state.category_type;

            store.state.validation = {
                nameHasError: false,
                nameMessageError: '',
                parentHasError: false,
                parentMessageError: '',
            };

            closeModal('addCategoryModal');

            clearFields();

            toast(toast_message, {
                autoClose: 1000,
                theme: "auto",
                type: "success",
                transition: "slide"
            });
            
            if(type == 'despesa'){
                getExpenseCategories();
            }
            else if(type == 'receita'){
                getRevenueCategories();
            }

        }

    })
    .catch(err => {
    console.error(err); 
    })

}

const archiveCategory = async (category, archive) => {

    if(archive == 'Yes'){

        if(category.subs && category.subs.length > 0){
            openModal('hasSubModal');
            return;
        }

        openModal('confirmArchiveCategoryModal');

        var confirmButton = document.getElementById('confirmArchiveButton');
        confirmButton.addEventListener('click', async function () {
        // do something...
        
            let data = new FormData();

            data.append('archived', archive);

            let url = '/categories/archive/'+category.id;
            let toast_message = 'Categoria arquivada com sucesso!';

            if(archive == 'No'){
                toast_message = 'Categoria desarquivada com sucesso!';
            }

            await axios.post(url, data)
            .then(res => {

                if(res.data.error){

                    console.log(res);

                }
                else{

                    toast(toast_message, {
                        autoClose: 1000,
                        theme: "auto",
                        type: "success",
                        transition: "slide"
                    });

                    closeModal('confirmArchiveCategoryModal');
                    
                    if(category.type == 'despesa'){
                        getExpenseCategories();
                    }
                    else if(category.type == 'receita'){
                        getRevenueCategories();
                    }

                }

            })
            .catch(err => {
            console.error(err); 
            })
            

        })

    }
    else{

        let data = new FormData();

        data.append('archived', archive);

        let url = '/categories/archive/'+category.id;
        let toast_message = 'Categoria arquivada com sucesso!';

        if(archive == 'No'){
            toast_message = 'Categoria desarquivada com sucesso!';
        }

        await axios.post(url, data)
        .then(res => {

            if(res.data.error){

                console.log(res);

            }
            else{

                toast(toast_message, {
                    autoClose: 1000,
                    theme: "auto",
                    type: "success",
                    transition: "slide"
                });
                
                if(category.type == 'despesa'){
                    getExpenseCategories();
                }
                else if(category.type == 'receita'){
                    getRevenueCategories();
                }

            }

        })
        .catch(err => {
        console.error(err); 
        })

    }    

}

const deleteCategory = async (category) => {

    store.state.deleted_category = category

    if(category.type == 'despesa'){

        let categories = Object.entries(store.state.categoriesExpense);

        for(let i = 0; i <= categories.length - 1; i++){

            if(categories[i][1].id != category.id){

                selectCategoriesMove.push({
                    id: categories[i][1].id,
                    name: categories[i][1].name
                });

                if(categories[i][1].subs){

                    let subs = categories[i][1].subs;

                    for(let s = 0; s <= subs.length - 1; s++){

                        if(subs[s].id != category.id){

                            selectCategoriesMove.push({
                                id: subs[s].id,
                                name: subs[s].name,
                                sub: true
                            });

                        }

                    }

                }

            }

        }

    }
    else if(category.type == 'receita'){

        let categories = Object.entries(store.state.categoriesRevenue);

        for(let i = 0; i <= categories.length - 1; i++){

            if(categories[i][1].id != category.id){

                selectCategoriesMove.push({
                    id: categories[i][1].id,
                    name: categories[i][1].name
                });

                if(categories[i][1].subs){

                    let subs = categories[i][1].subs;

                    for(let s = 0; s <= subs.length - 1; s++){

                        if(subs[s].id != category.id){

                            selectCategoriesMove.push({
                                id: subs[s].id,
                                name: subs[s].name,
                                sub: true
                            });

                        }

                    }

                }

            }

        }

    }

    openModal('deleteCategoryModal');

    var confirmButton = document.getElementById('confirmExclusionCategoryButton');
    confirmButton.addEventListener('click', async function () {
    // do something...
    
        let data = new FormData();

        data.append('before', category.id);
        data.append('after', store.state.destiny_category);

        let url = '/categories/delete';
        let toast_message = 'Categoria excluída com sucesso!';

        await axios.post(url, data)
        .then(res => {

            if(res.data.error){

                console.log(res);

            }
            else{

                toast(toast_message, {
                    autoClose: 1000,
                    theme: "auto",
                    type: "success",
                    transition: "slide"
                });

                closeModal('deleteCategoryModal');
                
                if(category.type == 'despesa'){
                    getExpenseCategories();
                }
                else if(category.type == 'receita'){
                    getRevenueCategories();
                }

            }

        })
        .catch(err => {
        console.error(err); 
        })
        

    })

}

const getContrastYIQ = (hexcolor) => {
    var r = parseInt(hexcolor.substring(1,3),16);
    var g = parseInt(hexcolor.substring(3,5),16);
    var b = parseInt(hexcolor.substring(5,7),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
}

</script>

<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">

                <div class="card">
                    <div class="card-header pb-0">
                        <div class="row align-items-center">
                            <div class="col-6">
                                <h6>Categorias</h6>
                            </div>
                            <div class="col-6">
                                <div class="dropdown float-end">
                                    <a class="me-3 trasation-list-add-button mt-n1 mb-2 text-dark" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fal fa-plus-circle"></i> <span class="d-none d-lg-inline-flex">Acidionar</span>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-end text-center" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#addCategoryModal" @click="createCategory('despesa')">Categoria de Despesa</a>
                                        </li>
                                        <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#addCategoryModal" @click="createCategory('receita')">Categoria de Receita</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">
                        <div class="col-12">

                            <div class="row">

                                <div class="col-11 mx-auto mt-3 mb-3">

                                    <ul class="nav nav-tabs justify-content-center no-border" id="categories-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="out-categorie-tab" data-bs-toggle="tab" data-bs-target="#out-categorie" type="button" role="tab" aria-controls="out-categorie" aria-selected="true">Despesas</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="in-categorie-tab" data-bs-toggle="tab" data-bs-target="#in-categorie" type="button" role="tab" aria-controls="in-categorie" aria-selected="false">Receitas</button>
                                        </li>
                                    </ul>

                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active mt-3" id="out-categorie" role="tabpanel" aria-labelledby="out-categorie-tab">

                                            <div class="col-12 text-center mt-4" v-if="store.state.loadingCategoriesExpense">
                                                <img :src="loadGif" alt="" width="60px">
                                            </div>

                                            <div class="col-12 text-center mt-4" v-if="store.state.categoriesExpense.length == 0">
                                                <p>Nenhuma categoria cadastrada.</p>
                                            </div>

                                            <CategoriesRow
                                                v-for="(category, index) in store.state.categoriesExpense" :key="index"
                                                :name="category.name"
                                                :id="category.id"
                                                :icon="category.icon"
                                                :color_icon="category.color"
                                                :subs="category.subs"
                                                :edit="category"
                                                @edit="editCategory"
                                                @archive="archiveCategory"
                                                @delete="deleteCategory"
                                            />

                                        </div>
                                        <div class="tab-pane fade" id="in-categorie" role="tabpanel" aria-labelledby="in-categorie-tab">

                                            <div class="col-12 text-center mt-4" v-if="store.state.loadingCategoriesRevenue">
                                                <img :src="loadGif" alt="" width="60px">
                                            </div>

                                            <div class="col-12 text-center mt-4" v-if="store.state.categoriesRevenue.length == 0">
                                                <p>Nenhuma categoria cadastrada.</p>
                                            </div>

                                            <CategoriesRow
                                                v-for="(category, index) in store.state.categoriesRevenue" :key="index"
                                                :name="category.name"
                                                :id="category.id"
                                                :icon="category.icon"
                                                :color_icon="category.color"
                                                :subs="category.subs"
                                                :edit="category"
                                                @edit="editCategory"
                                                @archive="archiveCategory"
                                                @delete="deleteCategory"
                                            />

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="modal fade" id="addCategoryModal" tabindex="-1" aria-labelledby="addCategoryModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row">

                        <div class="col-10">
                            <b>
                                Criando categoria de {{ store.state.category_type }}
                            </b>
                        </div>

                        <div class="col-2 text-end">
                            <a type="button" data-bs-dismiss="modal" @click="clearFields()">
                                <i class="fal fa-times-circle fa-lg"></i>
                            </a>
                        </div>

                        <div class="col-11 mx-auto">

                            <div class="row align-items-center mt-5">
                                <div class="col-3 category-create-icon-div">

                                    <div class="text-center shadow icon icon-shape align-self-center rounded-circle ms-2 me-3" :style="'background-color: '+store.state.category_color">
                                        <i :class="'icon-create fal ' + store.state.category_icon" :style="'color:'+getContrastYIQ(store.state.category_color)"></i>
                                    </div>

                                </div>
                                <div class="col-9 mb-3">

                                    <label for="categoryName">Nome da categoria</label>
                                    <input 
                                        type="text" 
                                        class="form-control form-control-lg" 
                                        id="categoryName"
                                        name="categoryName"
                                        placeholder="Nome da categoria"
                                        v-model="store.state.category_name"
                                        :class="store.state.validation.nameHasError ? 'is-invalid' : ''"
                                    >
                                    <div id="categoryNameFeedback" class="invalid-feedback">
                                        {{ store.state.validation.nameMessageError }}
                                    </div>
                                    

                                </div>
                                <div class="col-12 mb-1">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="store.state.category_sub">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Criar como subcategoria
                                        </label>
                                    </div>
                                </div>
                                <div v-if="store.state.category_sub" class="col-12 mb-3">
                                    <label for="categoryName">Categoria pai</label>
                                    <select 
                                        class="form-select form-select-lg" 
                                        aria-label="Categoria Pai" 
                                        v-model="store.state.category_parent"
                                        :class="store.state.validation.parentHasError ? 'is-invalid' : ''"
                                        id="parentSelect"
                                    >
                                            <option value="0" selected disabled>Selecione a categoria pai</option>
                                            <option
                                                v-for="(category, index) in selectCategories" :key="index"
                                                :value="category.id"
                                            >
                                                {{ category.name }}
                                            </option>
                                    </select>
                                    <div id="parentSelectFeedback" class="invalid-feedback">
                                        {{ store.state.validation.parentMessageError }}
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <hr>
                            </div>

                            <div class="row">

                                <div class="col-12">
                                    <p>Escolha um icone</p>
                                </div>

                                <div class="col-12 icon-list">

                                    <div class="row justify-content-center">

                                        <div
                                            v-for="(icon, index) in icons" :key="index"
                                            @click="store.state.category_icon = icon.name"
                                            class="color-picker rounded-circle d-flex align-items-center text-dark" 
                                        >
                                            <i :class="'ms-n1 fal '+icon.name"></i>
                                        </div>

                                    </div>

                                </div>
                                
                            </div>

                            <div class="row mt-2 mb-2">
                                <hr>
                            </div>

                            <div class="row">

                                <div class="col-12">
                                    <p>Escolha uma cor</p>
                                </div>
                                
                                <div
                                    v-for="(color, index) in colors" :key="index"
                                    @click="store.state.category_color = color"
                                    class="color-picker rounded-circle" 
                                    :style="'background-color: '+color+';'"
                                    ></div>
                            </div>

                            <div class="row mt-2 mb-2">
                                <hr>
                            </div>

                            <div class="row">
                                <div class="col-12 mt-3 mb-3 text-center">
                                    <button 
                                        @click="submit_category()"
                                        class="btn btn-success"
                                    >
                                        {{ store.state.category_id == '' ? "Criar Categoria" : "Salvar Alterações" }}
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="confirmArchiveCategoryModal" tabindex="-1" aria-labelledby="confirmArchiveCategoryModal" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row">

                        <div class="col-11 mx-auto mt-4 mb-4 text-center">
                            <h4 class="mb-4">Você quer mesmo arquivar essa categoria?</h4>
                            <p>
                                Categorias arquivadas não serão exibidas ao criar ou editar um lançamento, ok? Também não serão listadas nos filtros. Porém, os lançamentos vinculados à categoria arquivada continuam sendo exibidos normalmente em relatórios, tela de lançamentos e etc.
                                <br>
                                <br>
                                Você poderá reverter o arquivamento a qualquer momento.
                            </p>
                        </div>
                        <div class="col-12 text-center">
                            <button
                                id="confirmArchiveButton" 
                                class="btn btn-success"
                            >
                                Arquivar Categoria
                            </button>

                            <a @click="closeModal('confirmArchiveCategoryModal')" class="btn btn-link">Cancelar</a>
                        </div>

                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="hasSubModal" tabindex="-1" aria-labelledby="hasSubModalModal" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row">

                        <div class="col-11 mx-auto mt-4 mb-4 text-center">
                            <h4 class="mb-4">Exclua as subcategorias para poder arquivar</h4>
                            <p>
                                Você tem subcategorias registradas nessa categoria.
                                <br>
                                Exclua-as primeiro para prosseguir.
                            </p>
                        </div>
                        <div class="col-12 text-center">
                            <button
                                @click="closeModal('hasSubModal')"
                                class="btn btn-success"
                            >
                                Ok, entendi
                            </button>
                        </div>

                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="deleteCategoryModal" tabindex="-1" aria-labelledby="deleteCategoryModal" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row">

                        <div class="col-11 mx-auto mt-4 mb-2 text-center">
                            <h4 class="mb-4">Antes de excluir, mova seus lançamentos para outra categoria</h4>
                            
                        </div>
                        <div class="col-11 mx-auto">
                            <div class="mb-3">
                                <label for="category_delete" class="form-label">Movendo gastos de</label>
                                <input type="text" class="form-control form-control-lg" id="category_delete" readonly v-model="store.state.deleted_category.name">
                            </div>
                            <div class="mb-3">
                                <label for="category_delete_select" class="form-label">Para a categoria</label>
                                <select v-model="store.state.destiny_category" name="category_delete_select" id="category_delete_select" class="form-control form-control-lg">
                                    <option value="0" selected>Selecione a categoria</option>
                                    <option
                                        v-for="(category, index) in selectCategoriesMove" :key="index"
                                        :value="category.id"
                                    >
                                        {{category.sub ? '==>  ' : '' }}{{category.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 text-center">
                            <button
                                id="confirmExclusionCategoryButton"
                                @click="closeModal('hasSubModal')"
                                class="btn btn-danger text-white"
                                :class="store.state.destiny_category == 0 ? 'disabled' : ''"
                            >
                                Excluir Categoria
                            </button>
                            <a @click="closeModal('deleteCategoryModal')" class="btn btn-link">Cancelar</a>
                        </div>

                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<style>
    .configuration-button{
        text-align: start !important;
    }
    .no-border{
        border: none !important;
    }
    #categories-tab .nav-link.active{
        border-bottom: 2px solid #20c997 !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
    }
    #categories-tab .nav-link{
        border: none !important;
        color:#000
    }

    .category-create-icon-div{
        height: 85px;
    }

    .category-create-icon{
        min-height: 100%;
    }

    .color-picker{
        margin: 2px 4px 2px 0;
        width: 43px !important;
        height: 43px !important;
        cursor: pointer;
        font-size: 22px;
    }

    .color-picker .active{
        border: 4px solid #cecece;
    }

    .icon-picker{
        margin: 2px 4px 2px 0;
        width: 43px !important;
        height: 43px !important;
        cursor: pointer;
    }

    .icon-create{
        font-size: 20px !important;
    }

    .icon-list{
        height: 150px;
        overflow-x:auto;
    }

    @media (max-width: 575.98px) {

        .category-create-icon-div{
            height: 60px;
        }
    }
</style>
