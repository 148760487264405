<script setup>
defineProps({
    name: {
        type: String,
        required: true
    },
    limit: {
        type: Number,
        required: true,
    },
    value: {
        type: Number,
        required: true,
    },
    before_value: {
        type: Number,
        required: false,
    },
    due_date: {
        type: String,
        default: "",
    },
    status: {
        type: String,
        default: "text-success"
    },
    id: {
        type: String,
        default: "",
    },
    icon: {
        type: String,
        default: "",
    },
    sum: {
        type: Number,
        required: false
    },
    fixes: {
        type: Number,
        required: false
    },
    installments: {
        type: Number,
        required: false
    }
    
});

function formatMoney(v) {
      let total = parseFloat(v);

      return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    }

function formatDate(d){
    
    let date = new Date(d);

    let options = {
                    month: 'numeric',
                    day: 'numeric',
                    timeZone: 'UTC'
                };
    
    return date.toLocaleDateString('pt-BR', options);

}

function calcPercent(limit, limit_used){

    let percent = (parseFloat(limit_used) / parseFloat(limit)) * 100;

    return percent

}
</script>

<template>
    <div class="row align-items-center row-cards">

        <div class="col-10 align-content-between d-flex align-items-center">
            <img :src="icon" alt="Banco Logo" width="48" class="rounded-circle ms-2 me-3">
            <div>
                <h6 class="mt-n1 mb-n1">{{ name }}</h6>
                
                <span class="text-sm">{{ formatDate(due_date) }}</span>
            </div>
        </div>

        <div class="col-2 text-end">

            <a :href="'/fatura/'+id" class="me-3">
                <i class="fal fa-file-invoice text-black-50 row-accounts-list-icons-actions" v-tooltip="{
                                    content:'Ver fatura',
                                    distance: 15
                                }"></i>
            </a>

        </div>

        <div class="col-11 mx-auto mt-2 mb-1">
            <div class="row">
                <div class="col text-xs">
                    Limite
                    <br>
                    <span class="text-dark font-weight-bold">{{ formatMoney((parseFloat(limit))) }}</span>
                </div>
                <div class="col text-xs text-center">
                    Em aberto
                    <br>
                    <span class="text-dark font-weight-bold">{{ formatMoney((parseFloat(value) + parseFloat(before_value))) }}</span>
                </div>
                <div class="col text-xs text-end">
                    Lim. disponivel
                    <br>
                    <span class="text-dark font-weight-bold">
                        {{ formatMoney(parseFloat(limit) - (parseFloat(value) + parseFloat(installments ? installments : 0) + parseFloat(before_value))) }}
                    </span>
                </div>
            </div>
        </div>

        <div class="col-11 mx-auto mb-4">
            <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar" :style="'width: '+calcPercent(limit, (parseFloat(value) + parseFloat(installments ? installments : 0) + parseFloat(before_value)))+'%'" :class="calcPercent(limit, (parseFloat(value) + parseFloat(installments ? installments : 0) + parseFloat(before_value))) >= 90 ? 'bg-danger' : calcPercent(limit, (parseFloat(value) + parseFloat(installments ? installments : 0) + parseFloat(before_value))) >= 30 ? 'bg-warning' : calcPercent(limit, (parseFloat(value) + parseFloat(installments ? installments : 0) + parseFloat(before_value))) >= 0 ? 'bg-success' : ''"></div>
            </div>
        </div>

    </div>
</template>

<style>
    .row-cards{
        min-height: 70px;
    }

    .card-title{
            font-size: 14px;
        }

    @media (max-width: 575.98px) {
        .card-title{
            font-size: 13px;
        }
    }
</style>